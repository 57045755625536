import { Periode } from '../../components/PeriodeCarousel';
import { BASE_API_URL } from '../../utils/config/ConfigConstants';
import { parseDate } from '../../utils/datetime';

export const generatePayslipUrl = (
  periode: Periode | null,
  employeeNumber: string,
): string | undefined => {
  if (periode) {
    const { start, end } = periode;
    return `${BASE_API_URL}/payslips?employeeNumber=${employeeNumber}&startDate=${parseDate(
      start,
    )!.toISOString()}&endDate=${parseDate(end)!.toISOString()}`;
  }
  return undefined;
};
